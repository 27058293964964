<script>
// import Multiselect from "vue-multiselect";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import ApiClass from "../../api/api";

/**
 * Contacts-grid component
 */
export default {
  page: {
    title: "Notification List",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      loading: false,
      title: "Notification List",
      items: [
        {
          text: "Notification List",
          href: "#",
        },
        {
          text: "Notification",
          active: true,
        },
      ],
      loadingfirst: true,
      statusArr: [
        { key: "true", value: "Active" },
        { key: "false", value: "InActive" },
      ],
      fields: [
        {
          key: "notify_type",
          value: "notify_type",
        },
        {
          key: "User",
          value: "user",
        },
        {
          key: "Created_At",
          value: "created_at",
        },
        {
          key: "Expired_At",
          value: "expired_at",
        },
        "View",
      ],
      isStatus: "true",
      allData: [],
      viewtempdata: "",
    };
  },
  mounted() {
    this.TypeDetails();
  },
  methods: {
    async TypeDetails() {
      var result = await ApiClass.getRequest(
        "admin/notification/get?per_page=16&page=1",
        true
      );
      // console.log(result);
      if (result.data.status_code == 1) {
        this.loadingfirst = false;
        this.allData = result.data.data.data;
        // console.log(this.allData);
      }
    },

    viewtemp(item) {
      this.viewtempdata = item;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <!-- <filter section> -->

    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Notification List</h4>

            <!-- No Result Found -->
            <div v-if="!loadingfirst">
              <b-table
                striped
                hover
                :items="allData"
                :fields="fields"
                :busy="loadingfirst"
                ref="table"
                show-empty
                sortable="false"
              >
                <template #empty>
                  <div class="d-flex justify-content-center mb-3">
                    No Record Found
                  </div>
                </template>

                <template #table-busy>
                  <div class="text-center text-info my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <!-- <strong>Loading...</strong> -->
                  </div>
                </template>

                <!-- A virtual column -->
                <template #cell(notify_type)="data">
                  <span style="text-transform: capitalize">
                    {{ data.item.type }}</span
                  >
                </template>

                <template v-slot:cell(User)="data">
                  <div>
                    <span v-if="data.item.user" style="font-weight: 700"
                      >{{ data.item.user.name }}
                    </span>
                    <span v-else style="color: red; font-weight: 700">
                      ALL USERS
                    </span>
                  </div>
                </template>

                <template v-slot:cell(Created_At)="data">
                  {{ new Date(data.item.created_at).toLocaleDateString() }}
                </template>
                <template v-slot:cell(Expired_At)="data">
                  {{ data.item.expired_at }}
                </template>

                <template v-slot:cell(view)="data">
                  <div>
                    <i
                      class="mdi mdi-compass-outline"
                      v-b-modal.view
                      @click="viewtemp(data.item.content)"
                    ></i>
                  </div>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- view model -->
    <b-modal id="view" size="lg" title="Notification Viewer Section">
      <p><span v-html="viewtempdata"></span></p>
    </b-modal>
  </Layout>
</template>
